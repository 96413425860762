<!-- SearchHelp.vue -->
<template>
  <v-card flat>
    <v-card-title class="headline">
      {{ noResults ? 'No Results Found' : 'Search Tips' }}
    </v-card-title>
    
    <v-card-text>
      <div v-if="noResults" class="mb-4">
        <p class="subtitle-1 font-weight-medium">Try modifying your search using these techniques:</p>
      </div>

      <!-- Basic Search -->
      <div class="search-technique mb-6">
        <h3 class="title">Basic Search</h3>
        <p class="body-1">Enter keywords to search across titles, descriptions, and content.</p>
        <v-card outlined class="mt-2">
          <v-card-text>
            <div class="font-weight-medium mb-2">Examples:</div>
            <ul class="ml-4">
              <li><code>meditation practice</code> - Finds documents containing both words</li>
              <li><code>धर्म dharma</code> - Search in multiple languages</li>
            </ul>
          </v-card-text>
        </v-card>
      </div>

      <!-- Wildcard Search -->
      <div class="search-technique mb-6">
        <h3 class="title">Wildcard Search</h3>
        <p class="body-1">Use * to match any characters. Select "Wildcard" from the search type dropdown.</p>
        <v-card outlined class="mt-2">
          <v-card-text>
            <div class="font-weight-medium mb-2">Examples:</div>
            <ul class="ml-4">
              <li><code>medit*</code> - Matches meditation, meditative, meditating, etc.</li>
              <li><code>*शास्त्र</code> - Matches words ending in शास्त्र</li>
            </ul>
          </v-card-text>
        </v-card>
      </div>

      <!-- Regex Search -->
      <div class="search-technique mb-6">
        <h3 class="title">Regular Expression Search</h3>
        <p class="body-1">Use regex patterns for advanced matching. Select "Regex" from the search type dropdown.</p>
        <v-card outlined class="mt-2">
          <v-card-text>
            <div class="font-weight-medium mb-2">Examples:</div>
            <ul class="ml-4">
              <li><code>क[रल]म</code> - Matches करम or कलम</li>
              <li><code>dharm[ai]</code> - Matches dharma or dharmi</li>
            </ul>
          </v-card-text>
        </v-card>
      </div>

      <!-- Filters -->
      <div class="search-technique">
        <h3 class="title">Using Filters</h3>
        <p class="body-1">Combine your search with filters to narrow down results:</p>
        <v-card outlined class="mt-2">
          <v-card-text>
            <ul class="ml-4">
              <li>Use the language filter to search in specific languages</li>
              <li>Filter by document types to focus on specific content</li>
              <li>Select specific documents to search within them</li>
            </ul>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SearchHelp',
  props: {
    noResults: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}

ul {
  list-style-type: disc;
}

.search-technique h3 {
  margin-bottom: 8px;
}
</style>