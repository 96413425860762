<!-- SearchPage.vue -->
<template>
  <div class="search-page">
    <!-- Search Bar Section -->
    <div class="search-section">
      <SearchBar 
        ref="searchbar" 
        :loading="searching" 
        :debug-data="response?.debug"
        @search="onSearchBarSearch"
      />
    </div>
    <div v-if="hasActiveFilters" class="active-filters-section">
      <v-container class="py-2">
        <div class="d-flex align-center flex-wrap">
          <span class="active-filters-label mr-2">Active filters:</span>
          
          <v-chip
            v-for="[key, value] in facets"
            :key="`${key}-${value}`"
            small
            class="mr-2 mb-1"
            close
            @click:close="removeFilter(key, value)"
          >
            {{ formatFilterLabel(key) }}: {{ decodeURIComponent(value) }}
          </v-chip>
          
          <v-btn
            x-small
            text
            color="primary"
            @click="clearAllFilters"
            class="ml-auto"
          >
            Clear all filters
          </v-btn>
        </div>
      </v-container>
    </div>
    <!-- Main Content -->
    <v-container class="search-container" fluid>
      <v-row no-gutters>
        <!-- Filters Column -->
        <v-col cols="12" md="3" class="pr-md-4">
          <Filters 
            v-if="response?.facets && Object.keys(processFacets).length > 0"
            :facets="processFacets" 
            :initial-selected="facets"
            @change="onFilterChange" 
          />
        </v-col>

        <!-- Results Column -->
        <v-col cols="12" md="9">
          <div v-if="response" class="results-container">
            <!-- Results Header -->
            <div class="results-header">
              <span class="results-count">
                {{ response.count ? `${1 + pageOffset} - ${pageOffset + response.results.length} of ${response.count} results` : 'No results' }}
                <template v-if="query">
                  for "<span class="query-text">{{ query }}</span>"
                </template>
              </span>
              <span v-if="response.took" class="response-time">
                ({{ response.took }}ms)
              </span>
            </div>

            <!-- Results List -->
            <v-card
              v-if="response.count"
              flat
              class="results-card"
            >
              <v-list three-line class="results-list">
                <template v-for="(item, index) in response.results">
                  <v-list-item 
                    :key="item.id"
                    class="result-item"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="result-title">
                        <!-- <span>{{ item.title || 'Untitled' }} </span> -->
                        <router-link :to="getSearchUrl(item)">
                          {{ item.title || 'Untitled' }}
                        </router-link>
                        <v-chip
                          x-small
                          outlined
                          class="score-chip"
                          color="primary"
                          title="Relevance Score"
                        >
                          {{ (item.score || 0).toFixed(2) }}
                        </v-chip>
                      </v-list-item-title>

                      <v-list-item-subtitle 
                        v-if="item.highlights"
                        class="result-highlights"
                      >
                        <template v-for="(highlight, field) in item.highlights">
                          <div 
                            :key="field"
                            class="highlight-text"
                            v-html="highlight"
                          ></div>
                        </template>
                      </v-list-item-subtitle>
                      <Breadcrumb :document-item="item.documentitemid" :document="item.documentitemid.documentid" />
                      <div class="result-metadata">
                        <v-chip 
                          v-if="item.documentid?.documenttypeid?.name"
                          x-small 
                          outlined
                          class="metadata-chip"
                        >
                          {{ item.documentid.documenttypeid.name }}
                        </v-chip>
                        <v-chip 
                          v-if="item.language?.english_name"
                          x-small 
                          outlined
                          class="metadata-chip"
                        >
                          {{ item.language.english_name }}
                        </v-chip>
                        <span 
                          v-if="item.documentid?.englishtitle" 
                          class="document-title"
                        >
                          {{ item.documentid.englishtitle }}
                        </span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider 
                    v-if="index < response.results.length - 1" 
                    :key="`div-${item.id}`"
                    class="result-divider"
                  ></v-divider>
                </template>
              </v-list>

              <!-- Pagination -->
              <div class="pagination-container">
                <v-pagination
                  v-if="response.count > pageSize"
                  v-model="page"
                  :length="Math.ceil(response.count / pageSize)"
                  :total-visible="7"
                  @change="onPageChange"
                  class="search-pagination"
                ></v-pagination>
              </div>
            </v-card>

            <!-- No Results -->
            <v-card v-else flat class="no-results-card">
              <SearchHelp :no-results="true" />
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchBar from '@/components/search/SearchBar'
import SearchHelp from '@/components/search/SearchHelp'
import Filters from '@/components/search/Filters'
import Breadcrumb from '@/components/documentItems/Breadcrumb'
import { debounce } from 'lodash'

export default {
  name: 'SearchPage',
  components: {
    SearchBar,
    SearchHelp,
    Filters,
    Breadcrumb
  },
  data: () => ({
    query: null,
    page: 1,
    pageSize: 20,
    searching: false,
    facets: [],
    respondToRouteChanges: true,
    isInternalUpdate: false  // Add this flag
  }),
  computed: {
    ...mapState('search', { 
      response: 'documentSearchResults', 
      queryParams: 'documentSearchQueryParams' 
    }),
    hasActiveFilters() {
      return this.facets.length > 0;
    },
    pageOffset() { 
      return this.pageSize * (this.page - 1) 
    },
    processFacets() {
      if (!this.response?.facets) return {}
      
      // Create a shallow copy to avoid reactive issues
      return {
        document_types: [...(this.response.facets.document_types || [])],
        document_item_types: [...(this.response.facets.document_item_types || [])],
        documents: [...(this.response.facets.documents || [])],
        languages: [...(this.response.facets.languages || [])]
      }
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(newRoute, oldRoute) {
        // Skip if this is an internal update
        if (this.isInternalUpdate) return
        
        // Only handle actual query changes
        if (oldRoute && this.isQueryEqual(newRoute.query, oldRoute.query)) return

        // Load state and search if needed
        const gotData = this.loadStateFromQueryString()
        if (gotData) {
          this.doSearch()
        }
      }
    },
    page(newPage, oldPage) {
      if (newPage !== oldPage) {
        console.log('Page watcher triggered:', newPage); // Debug logging
        this.updateUrl({ ...this.$route.query, page: newPage.toString() });
      }
    }
  },
  mounted() {
    
  },
  methods: {
    formatFilterLabel(key) {
      const labels = {
        'doc_type': 'Document Type',
        'doc_item_type': 'Item Type',
        'document': 'Document',
        'language': 'Language'
      };
      return labels[key] || key;
    },
    
    removeFilter(key, value) {
      this.facets = this.facets.filter(([k, v]) => !(k === key && v === value));
      
      // Update URL and trigger new search
      const filterParams = {};
      this.facets.forEach(([k, v]) => {
        filterParams[k] = v;
      });
      
      this.updateUrl({ 
        ...filterParams,
        page: '1'
      });
      
      this.doSearch();
    },
    
    clearAllFilters() {
      this.facets = [];
      
      // Remove all filter params from URL, keep search query and type
      this.updateUrl({
        q: this.query,
        type: this.$route.query.type,
        page: '1'
      });
      
      this.doSearch();
    },

    updateUrl(newParams = {}) {
      if (this.isInternalUpdate) return;

      const updatedQuery = {};
      
      // Handle both single values and arrays
      Object.entries(newParams).forEach(([key, value]) => {
        if (value != null && value !== '') {
          if (Array.isArray(value)) {
            // For arrays with single items, store as single value
            updatedQuery[key] = value.length === 1 ? value[0] : value;
          } else {
            updatedQuery[key] = value.toString();
          }
        }
      });

      if (this.isQueryEqual(this.$route.query, updatedQuery)) return;

      this.isInternalUpdate = true;
      this.$router.replace({ query: updatedQuery })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error('Navigation error:', err);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isInternalUpdate = false;
          }, 0);
        });
    },
    
    loadStateFromQueryString() {
      const params = this.$route.query;
      let gotQueryData = false;

      // Handle search query
      if (params.q) {
        this.query = params.q;
        this.$refs.searchbar?.setQuery(params.q);
        gotQueryData = true;
      }

      // Handle language
      if (params.language) {
        this.$refs.searchbar?.setInitialLanguage(params.language);
        gotQueryData = true;
      }

      // Handle page number
      if (params.page) {
        const pageNum = parseInt(params.page, 10);
        if (!isNaN(pageNum) && pageNum > 0) {
          this.page = pageNum; // Make sure this triggers
          console.log('Setting page from URL:', pageNum); // Debug logging
          gotQueryData = true;
        } else {
          this.page = 1; // Reset to first page if invalid
        }
      } else {
        this.page = 1; // Reset when no page in URL
      }

      // Handle search type
      if (params.type) {
        this.$refs.searchbar?.setSearchType(params.type);
        gotQueryData = true;
      }

      // Handle filters - support both single values and arrays
      const qsFilters = [];
      Object.entries(params).forEach(([key, value]) => {
        if (!['q', 'page', 'type'].includes(key)) {
          if (Array.isArray(value)) {
            value.forEach(v => qsFilters.push([key, v]));
          } else if (value) {  // Only add if value exists
            qsFilters.push([key, value]);
          }
        }
      });

      if (qsFilters.length > 0) {
        this.facets = qsFilters;
        gotQueryData = true;
      } else {
        this.facets = [];  // Clear facets if none in URL
      }

      return gotQueryData;
    },

    handleRouteChange(route) {
      if (this.isInternalUpdate || this.searching) return
      
      const gotData = this.loadStateFromQueryString()
      if (gotData) {
        this.doSearch()
      }
    },
    getSearchUrl(item) {
      if (item.documentitemid?.breadcrumb) {
        const breadcrumb = JSON.parse(item.documentitemid.breadcrumb)
        const leafNode = breadcrumb.find(bi => bi.isleafnode)
        const lastItem = breadcrumb.slice(-1).pop()
        return `/app/document/${item.documentitemid.documentid?.documentid}/documentitem/${leafNode?.id}?documentitem_focus=${lastItem?.id}`
      }
      return null
    },
    isQueryEqual(query1, query2) {
      if (!query1 || !query2) return false
      const keys1 = Object.keys(query1)
      const keys2 = Object.keys(query2)
      if (keys1.length !== keys2.length) return false
      return keys1.every(key => query1[key] === query2[key])
    },

    async doSearch(searchType = 'default', language = null) {
      if ((!this.query && this.facets.length === 0) || this.searching) {
        return
      }

      const data = {
        query: this.query,
        page: this.page,
        pageSize: this.pageSize,
        filters: this.facets,
        type: searchType || this.$route.query.type || 'default',
        language: language || this.$route.query.language
      }

      try {
        this.searching = true
        await this.$store.dispatch('search/findDocuments', data)
      } catch (error) {
        console.error('Search error:', error)
      } finally {
        this.searching = false
      }
    },

    onSearchBarSearch({ query, type, language }) {
      if (this.searching) return
      
      this.query = query
      this.page = 1
      
      // Update URL with language parameter
      this.updateUrl({
        q: query,
        type,
        language,
        page: '1'
      })
      
      // Then search with language parameter
      this.doSearch(type, language)
    },

    onPageChange(page) {
      // Prevent redundant updates and check for valid page
      if (this.page === page || this.searching || !page) return;
      
      console.log('Page changing to:', page); // Add debug logging
      
      // Update local page state
      this.page = page;
      
      // Update URL with new page number
      const currentQuery = { ...this.$route.query };
      const newQuery = {
        ...currentQuery,
        page: page.toString()
      };

      // Update URL first
      this.updateUrl(newQuery);

      // Then trigger search
      this.doSearch();
    },

    onFilterChange(payload) {
      if (this.searching) return;

      // Create a new array to avoid reactive issues
      this.facets = [...payload];
      this.page = 1;

      // Group filters by key
      const filterParams = {};
      payload.forEach(([key, value]) => {
        filterParams[key] = filterParams[key] || [];
        filterParams[key].push(value);
      });

      // Convert to URL format and update
      const urlParams = {
        ...this.$route.query, // Keep existing query params
        ...Object.fromEntries(
          Object.entries(filterParams).map(([key, values]) => [
            key,
            values.length === 1 ? values[0] : values
          ])
        ),
        page: '1'
      };

      // Remove any undefined/null values
      Object.keys(urlParams).forEach(key => 
        urlParams[key] === undefined && delete urlParams[key]
      );

      // Update URL first
      this.updateUrl(urlParams);

      // Then search
      this.doSearch();
    },

    debouncedUpdateAndSearch: debounce(function(filterParams) {
      // Update URL first
      this.updateUrl({ 
        ...filterParams,
        page: '1'
      })

      // Then search
      this.doSearch()
    }, 300),

  }
}
</script>

<style scoped>
.active-filters-section {
  margin-top: -16px; /* To remove gap with search section */
}

.active-filters-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.search-page {
  min-height: 100vh;
  background: #f5f5f7;
}

.search-section {
  padding: 12px 0;  /* Reduced from 16px */
  margin-bottom: 16px;  /* Reduced from 24px */
}

.search-container {
  max-width: 1280px !important;  /* Reduced from 1440px */
  padding: 0 16px;  /* Reduced from 0 24px */
}

.results-container {
  margin-bottom: 40px;
}

.results-header {
  margin-bottom: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.query-text {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.response-time {
  color: rgba(0, 0, 0, 0.4);
}

.results-card {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.98) !important;
  overflow: hidden;
}

.result-item {
  padding: 16px !important;
  transition: background-color 0.2s ease;
}

.result-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.result-title {
  display: flex !important;
  align-items: center !important;
  gap: 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
}

.score-chip {
  font-size: 11px !important;
  height: 20px !important;
}

.result-highlights {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.highlight-text {
  margin-bottom: 4px;
}

.result-metadata {
  margin-top: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.metadata-chip {
  font-size: 11px !important;
  height: 20px !important;
}

.document-title {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.result-divider {
  margin: 0 16px !important;
  opacity: 0.08;
}

.pagination-container {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.search-pagination :deep(.v-pagination__item) {
  box-shadow: none !important;
}

mark {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 2px;
  border-radius: 2px;
}

@media (max-width: 960px) {
  .search-container {
    padding: 0 16px;
  }
}
</style>